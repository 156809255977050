import moment from 'moment';

jQuery( document ).ready( function ( $ ) {
	$.ajax( {
		        url: 'https://rest.bandsintown.com/artists/Slash/events?app_id=45PRESS_SLASH',
		        method: 'GET',
		        dataType: 'json',
		        success: function ( data, textStatus, jqXHR ) {
			        if ( data.length > 0 ) {
				        var html = '';

				        $.each( data, function event( key, value ) {
					        if ( moment( this.datetime ).isBefore( '2024-07-05' ) ) {
						        return;
					        }

					        var country_region = '';

					        if ( this.venue.region === '' || Number.isInteger( parseInt( this.venue.region ) ) ) {
						        country_region = this.venue.country;
					        } else {
						        country_region = this.venue.region;
					        }

					        html += '<div class="tour-date">';

					        html += '<div class="tour-date-venue">';

					        html += '<div class="tour-date-time">';
					        html += '<span>' + moment( this.datetime ).format( 'D MMM YY' ) + '</span>';
					        html += '</div>';

					        html += '<div class="tour-venue">';
					        //html += '<span>' + this.venue.name + '</span>';
					        html += '</div>';

					        html += '</div>';

					        html += '<div class="tour-date-location">';
					        html += '<span>' + this.venue.city + ', ' + country_region + '</span>';
					        html += '</div>';

					        console.log( this.offers );


					        html += '<div class="tour-date-tickets">';
					        if ( Array.isArray( this.offers ) && this.offers.length > 0 ) {
						        for ( var i = 0; i < this.offers.length; i ++ ) {
							        if ( this.offers[i].status === 'available' ) {
								        html += '<a href="' + this.offers[i].url + '" target="_blank" class="btn btn-ticket">' + this.offers[i].type + '</a>';
							        }
						        }
					        } else {
						        html += '<span>Coming Soon</span>';
					        }

					        html += '</div>';

					        html += '</div>';

				        } );

				        $( '#events' ).html( html );

			        } else {
				        $( '#events' ).html( '<p class="no-results">No upcoming events.</p>' );
			        }
		        },
		        error: function ( jqXHR, textStatus, errorThrown ) {
			        alert( 'An error has occured!' );
		        }
	        } );
} );
